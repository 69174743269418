export function RefreshBar({
  children,
  handleOnClick,
}: {
  children: React.ReactNode;
  handleOnClick: () => void;
}) {
  return (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
      <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
        <p className="text-sm leading-4 text-white">{children}</p>
        <button
          type="button"
          className="-m-0.5 flex-none p-0.5"
          onClick={() => handleOnClick()}
        >
          <span className="text-white text-sm underline">Update</span>
        </button>
      </div>
    </div>
  );
}
