import React from 'react';
import { OverviewCards } from './ui/overview';
import { PvDataProvider } from './hoos/useData';

function InfoCards() {
  return <OverviewCards />;
}

function Container({ children }: { children: React.ReactNode }) {
  return <div className="container mx-auto">{children}</div>;
}

function App() {
  return (
    <>
      <Container>
        <InfoCards />
      </Container>
    </>
  );
}

function AppWrapper() {
  return (
    <PvDataProvider>
      <App />
    </PvDataProvider>
  );
}

export default AppWrapper;
