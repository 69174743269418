import { useData } from '../hoos/useData';
import { classNames } from '../utils';

export function OverviewCards() {
  const { overViewData, isLoading } = useData();

  const battery =
    (overViewData?.BatteryCharge || 0) - (overViewData?.BatteryDischarge || 0);

  const grid =
    overViewData?.FeedInPower > 0
      ? overViewData.FeedInPower
      : overViewData.GridConsumption;

  const stats = [
    {
      name: 'Batterie',
      stat: `${formatNumber(3, battery)}`,
      unit: 'kwh',
      total: '',
      change: battery > 0 ? 'lädt' : 'entlädt',
      changeType: isLoading ? '' : battery > 0 ? 'in' : 'out',
    },
    {
      name: 'PV',
      stat: `${formatNumber(3, overViewData?.PVPower)}`,
      unit: 'kwh',
      total: `${formatNumber(2, overViewData?.PVPowerSum)} kw`,
      change: '',
      changeType: '',
    },
    {
      name: 'Netz',
      stat: `${formatNumber(2, grid)}`,
      unit: 'kwh',
      total: `${formatNumber(2, overViewData?.FeedInSum)} kw`,
      change: overViewData.FeedInPower > 0 ? 'export' : 'import',
      changeType: isLoading ? '' : overViewData.FeedInPower > 0 ? 'in' : 'out',
    },
  ];

  return (
    <>
      <dl className="mt-5 mx-2 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6 relative">
            <dt className="text-base font-normal text-gray-600">{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block md:flex">
              {!isLoading ? (
                <div className="flex items-baseline text-2xl justify-between font-semibold text-gray-800">
                  {item.stat}
                  <span className="ml-2 text-sm font-medium text-gray-500">
                    {item.unit}
                  </span>
                </div>
              ) : (
                <div
                  className={'animate-pulse rounded-md bg-gray-200 h-7 w-1/3'}
                />
              )}

              {!isLoading && (
                <span className="ml-2 text-md font-medium text-gray-500">
                  {item.total}
                </span>
              )}

              {item.changeType !== '' && (
                <div
                  className={classNames(
                    item.changeType === 'in'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800',
                    'absolute top-6 right-6 inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
                  )}
                >
                  <span className="sr-only">
                    {' '}
                    {item.changeType === 'out' ? 'Out' : 'In'} by{' '}
                  </span>
                  {item.change}
                </div>
              )}
            </dd>
          </div>
        ))}
      </dl>
    </>
  );
}

function formatNumber(decimals: number, value?: number): string {
  if (value === undefined) {
    return 'N/A';
  }
  return value.toFixed(decimals).replace('.', ',');
}
