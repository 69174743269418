import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { fetchOverview, OverviewData } from '../api';
import { RefreshBar } from '../ui/refreshBar';

export interface PvData {
  isLoading: boolean;
  hasAuth: boolean;
  lastUpdateTime: string;

  overViewData: OverviewData;

  actions: {
    fetchOverviewData: () => void;
  };
}

const PvDataContext = createContext<PvData>({
  isLoading: true,
  hasAuth: false,
  actions: {
    fetchOverviewData: () => {},
  },
  lastUpdateTime: '',
  overViewData: {
    LastUpdate: '',
    FeedInPower: 0,
    FeedInSum: 0,
    GridConsumption: 0,
    GridConsumptionSum: 0,
    UsedPower: 0,
    UsedPowerSum: 0,
    BatteryCharge: 0,
    BatteryDischarge: 0,
    PVPower: 0,
    PVPowerSum: 0,
  },
});

export const useData = (): PvData => {
  const context = useContext(PvDataContext);

  if (context === undefined) {
    throw new Error('useData must be used within a PvDataProvider');
  }
  return context;
};

export const PvDataProvider = ({ children }: React.PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasAuth] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState<string>('' as string);

  const [overViewData, setOverViewData] = useState<OverviewData>(
    {} as OverviewData,
  );

  const fetchOverviewData = useCallback(async () => {
    setIsLoading(true);
    const data = await fetchOverview();
    if (data) {
      setOverViewData(data);
    }
    setIsLoading(false);
    setLastUpdateTime(new Date().toLocaleTimeString());
  }, []);

  useEffect(() => {
    fetchOverviewData();
    const timer = setTimeout(() => {
      fetchOverviewData();
    }, 1000 * 60);

    return () => clearTimeout(timer);
  }, [fetchOverviewData]);

  return (
    <PvDataContext.Provider
      value={{
        isLoading: isLoading,
        hasAuth: hasAuth,
        lastUpdateTime: lastUpdateTime,
        overViewData,
        actions: { fetchOverviewData },
      }}
    >
      {children}
      {!isLoading && (
        <RefreshBar handleOnClick={() => fetchOverviewData()}>
          Letztes Update: {lastUpdateTime} Uhr - Daten von{' '}
          {formatToLocalTime(overViewData?.LastUpdate)} Uhr
        </RefreshBar>
      )}
    </PvDataContext.Provider>
  );
};

function formatToLocalTime(isoTimeString: string): string {
  const date = new Date(isoTimeString);
  return date.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' });
}
