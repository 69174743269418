const baseURL = '';
//const baseURL = 'https://pv.steu.de';

export type OverviewData = {
  LastUpdate: string;

  FeedInPower: number;
  FeedInSum: number;

  GridConsumption: number;
  GridConsumptionSum: number;

  UsedPower: number;
  UsedPowerSum: number;

  BatteryCharge: number;
  BatteryDischarge: number;

  PVPower: number;
  PVPowerSum: number;
};

export async function fetchOverview(): Promise<
  OverviewData | undefined | void
> {
  return fetch(`${baseURL}/api/overview`)
    .then((response) => response.json())
    .then((data) => {
      return {
        FeedInPower: data.feedin_power,
        FeedInSum: data.total_feedin,
        GridConsumption: data.gridconsumption_power,
        GridConsumptionSum: data.total_gridconsumption,
        UsedPower: data.used_power,
        UsedPowerSum: data.total_used_power,
        BatteryCharge: data.charge_energy_power,
        BatteryDischarge: data.discharge_energy_power,
        PVPower: data.pv_power,
        PVPowerSum: data.total_pv,
        LastUpdate: data.last_update,
      };
    });
}
